import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image2 from 'components/shared/Image2'

const Svg = styled(Image2)`
  @media (min-width: 576px) {
    width: 100%;
  }

  @media (max-width: 575px) {
    max-width: 150px;
    margin: 0 auto;
  }
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.text.purple};
`

interface DescriptionWithSvgProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_DescriptionWithSvg
}

const DescriptionWithSvg: React.FC<DescriptionWithSvgProps> = ({ fields }) => (
  <section className="my-4 my-lg-5">
    <div className="container py-4 py-lg-5">
      <div className="row">
        <div className="col-sm-3 d-flex align-items-center">
          <Svg image={fields.svg} />
        </div>
        <div className="col-sm-9 mt-4 mt-sm-0 d-flex align-items-center pl-lg-5">
          <Content content={fields.description} />
        </div>
      </div>
    </div>
  </section>
)

export default DescriptionWithSvg
